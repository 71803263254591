var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"match-height"},[_c('v-col',[_c('Breadcrumbs',{attrs:{"items":_vm.items}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{staticClass:"multi-col-validation"},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.formTitulo))]),_c('v-card-text',[_c('v-row',{staticClass:"pa-3 mt-2"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Nombre","outlined":"","dense":"","placeholder":"Nombre","error-count":"2","rules":[
                  function (v) { return !!v || 'El campo Nombre es obligatorio'; },
                  function (v) { return (!!v && v.length <= 50) || 'El campo Nombre hasta 50 caracteres'; } ],"counter":"50","maxlength":"50","error":_vm.campos.firstName.error,"error-messages":_vm.errorMsgFirstName},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-label',{attrs:{"field":"Nombre","required":"true"}})]},proxy:true}]),model:{value:(_vm.obj.firstName),callback:function ($$v) {_vm.$set(_vm.obj, "firstName", $$v)},expression:"obj.firstName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Apellidos","outlined":"","dense":"","placeholder":"Apellidos","error-count":"2","rules":[
                  function (v) { return !!v || 'El campo Apellido es obligatorio'; },
                  function (v) { return (!!v && v.length <= 50) || 'El campo Apellido hasta 50 caracteres'; } ],"counter":"50","maxlength":"50","error":_vm.campos.lastName.error,"error-messages":_vm.errorMsgLastName},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-label',{attrs:{"field":"Apellidos","required":"true"}})]},proxy:true}]),model:{value:(_vm.obj.lastName),callback:function ($$v) {_vm.$set(_vm.obj, "lastName", $$v)},expression:"obj.lastName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Usuario","outlined":"","dense":"","placeholder":"Usuario","error-count":"2","rules":[
                  function (v) { return !!v || 'El campo Usuario es obligatorio'; },
                  function (v) { return (!!v && v.length <= 50) || 'El campo Usuario hasta 50 caracteres'; } ],"counter":"50","maxlength":"50","error":_vm.campos.userName.error,"error-messages":_vm.errorMsgUserName},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-label',{attrs:{"field":"Usuario","required":"true"}})]},proxy:true}]),model:{value:(_vm.obj.userName),callback:function ($$v) {_vm.$set(_vm.obj, "userName", $$v)},expression:"obj.userName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Teléfono","outlined":"","dense":"","placeholder":"(09) 99999999","error-count":"2","counter":"50","maxlength":"50","error":_vm.campos.phone.error,"error-messages":_vm.errorMsgPhone},model:{value:(_vm.obj.phone),callback:function ($$v) {_vm.$set(_vm.obj, "phone", $$v)},expression:"obj.phone"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Correo","outlined":"","dense":"","placeholder":"Correo","suffix":_vm.emailSufix,"rules":[
                  function (v) { return !!v || 'El campo Correo es obligatorio'; },
                  function (v) { return _vm.isValidEmail(v) || 'Formato Correo es incorrecto'; } ],"counter":"50","maxlength":"50","error-count":"2","error":_vm.campos.email.error,"error-messages":_vm.errorMsgEmail},model:{value:(_vm.obj.email),callback:function ($$v) {_vm.$set(_vm.obj, "email", $$v)},expression:"obj.email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{ref:"refArea",attrs:{"items":_vm.listaAreas,"loading":_vm.loadingAreas,"item-value":"id","item-text":"name","label":"Área","dense":"","outlined":"","return-object":"","error":_vm.campos.area.error,"error-messages":_vm.errorMsgArea,"rules":[
                  function (v) { return !!v || 'El campo Área es obligatorio'; } ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('app-label',{attrs:{"field":"Área","required":"true"}})]},proxy:true}]),model:{value:(_vm.obj.area),callback:function ($$v) {_vm.$set(_vm.obj, "area", $$v)},expression:"obj.area"}})],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","md":"12"}},[_c('DualList',{attrs:{"items":_vm.listaProfiles,"item-text":"name","item-value":"id","mobile-breakpoint":_vm.mobileBreakpoint,"messages":_vm.messages,"icons":_vm.icons},model:{value:(_vm.obj.profiles),callback:function ($$v) {_vm.$set(_vm.obj, "profiles", $$v)},expression:"obj.profiles"}})],1)],1)],1),(true)?_c('v-card-actions',{staticClass:"mt-5"},[_c('v-row',{staticClass:"pa-3 mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loadingSaveForm},on:{"click":_vm.saveItem}},[_vm._v(" Enviar ")]),_c('v-btn',{staticClass:"mx-2",attrs:{"type":"reset","outlined":""},on:{"click":_vm.cancelarForm}},[_vm._v(" Cancelar ")])],1)],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }