export default {
  translations: {
    listOptions: 'Options',
    listSelected: 'Selected',
    forwardAll: 'Toggle all to selected',
    forward: 'Toggle to selected',
    backwardAll: 'Toggle all to options',
    backward: 'Toggle to options',
    find: 'Find',
  },

  iconsMdi: {
    mobile: {
      forwardAll: 'mdi-chevron-double-down',
      forward: 'mdi-chevron-down',
      backwardAll: 'mdi-chevron-double-up',
      backward: 'mdi-chevron-up',
    },
    desktop: {
      forwardAll: 'mdi-chevron-double-right',
      forward: 'mdi-chevron-right',
      backwardAll: 'mdi-chevron-double-left',
      backward: 'mdi-chevron-left',
    },
  },

  mobileBreakpoint: 960,
  height: 300,
  heightScroller: 300,
}
