var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{staticClass:"d-flex justify-center align-center",style:(_vm.styles),attrs:{"xs12":"","md1":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","small":"","text":"","color":"primary"},on:{"click":function($event){return _vm.toggle('backwardAll')}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.arrows.backwardAll))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.messages.backwardAll))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","small":"","text":"","color":"primary"},on:{"click":function($event){return _vm.toggle('backward')}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.arrows.backward))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.messages.backward))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","small":"","text":"","color":"primary"},on:{"click":function($event){return _vm.toggle('forward')}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.arrows.forward))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.messages.forward))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","small":"","text":"","color":"primary"},on:{"click":function($event){return _vm.toggle('forwardAll')}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.arrows.forwardAll))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.messages.forwardAll))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }