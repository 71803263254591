<template>
  <v-flex
    xs12
    md1
    class="d-flex justify-center align-center"
    :style="styles"
  >
    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
          fab
          small
          text
          color="primary"
          v-on="on"
          @click="toggle('backwardAll')"
        >
          <v-icon>{{ arrows.backwardAll }}</v-icon>
        </v-btn>
      </template>

      <span>{{ messages.backwardAll }}</span>
    </v-tooltip>

    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
          fab
          small
          text
          color="primary"
          v-on="on"
          @click="toggle('backward')"
        >
          <v-icon>{{ arrows.backward }}</v-icon>
        </v-btn>
      </template>

      <span>{{ messages.backward }}</span>
    </v-tooltip>

    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
          fab
          small
          text
          color="primary"
          v-on="on"
          @click="toggle('forward')"
        >
          <v-icon>{{ arrows.forward }}</v-icon>
        </v-btn>
      </template>

      <span>{{ messages.forward }}</span>
    </v-tooltip>

    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
          fab
          small
          text
          color="primary"
          v-on="on"
          @click="toggle('forwardAll')"
        >
          <v-icon>{{ arrows.forwardAll }}</v-icon>
        </v-btn>
      </template>

      <span>{{ messages.forwardAll }}</span>
    </v-tooltip>
  </v-flex>
</template>

<script>
export default {
  props: {
    messages: {
      type: Object,
      required: true,
    },

    icons: {
      type: Object,
      required: true,
    },

    isMobile: {
      type: Boolean,
      required: true,
    },

    height: {
      type: Number || String,
      required: true,
    },
  },

  computed: {
    /**
     * Arrows
     * Computed property with icons used in control buttons
     * Will return different icons based on {isMobile} prop
     *
     * @since  2020-04-09
     * @author Kenny Mochizuki
     *
     * @return {Object}
     *   Return {Object} with icons
     */
    arrows() {
      if (this.isMobile) {
        return this.icons.mobile
      }

      return this.icons.desktop
    },

    /**
     * Styles
     * Computed property with style of control
     * Will return different styles based on {isMobile} prop
     *
     * @since  2020-04-09
     * @author Kenny Mochizuki
     *
     * @return {String|CSS}
     *   Return CSS style string of styles
     */
    styles() {
      if (this.isMobile) {
        return `
          flex-direction: row;
        `
      }

      return `
        flex-direction: column;
        height: ${this.height + 100}px;
        max-height: ${this.height + 100}px;
      `
    },
  },

  methods: {
    /**
     * Toggle
     * Button click handler, will emit a Vue event
     * with action
     *
     * @since  2020-04-09
     * @author Kenny Mochizuki
     *
     * @param action {String}
     *   Valid options [backwardAll|backward|forward|forwardAll]
     */
    toggle(action) {
      this.$emit('toggle', { action })
    },
  },
}
</script>
