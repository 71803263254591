export default {
  MacOS: {
    range: 16, // AKA Shift
    indLeft: 91, // AKA Ctrl Left | MacOS Key = Cmd Left
    indRight: 92, // AKA Ctrl Right | MacOS Key = Cmd Right
  },
  Other: {
    range: 16, // AKA Shift
    indLeft: 17, // AKA Ctrl Left
    indRight: 17, // AKA Ctrl Right
  },
}
