<template>
  <div>
    <template v-if="hasVuetify">
      <v-layout
        row
        wrap
        justify-center
      >
        <v-flex
          xs12
          md5
        >
          <v-card class="transparencia">
            <v-card-title>
              <span style="font-size: 15px !important">
                {{ messages.listOptions }}
              </span>
            </v-card-title>

            <v-divider class="mx-2" />

            <v-card-text class="px-0 pb-0 transparencia">
              <v-text-field
                v-model="searchOptions"
                :placeholder="messages.find"
                hide-details
                outlined
                class="px-2 mb-2"
                dense
                @keypress="updateScroller('options')"
              />
              <v-list
                dense
                class="py-0 transparencia"
                :style="listStyle"
              >
                <gm-scroller
                  ref="scrollerOptions"
                  :size="40"
                  :remain="10"
                  class="scrollbar__seamless"
                  :style="listStyleListScroller"
                >
                  <template v-for="(item, i) in options">
                    <v-list-item
                      :key="`option-item-${i}`"
                      :class="
                        precheck.options.includes(item.optIdx) ? 'selected' : ''
                      "
                      @click="handleClick('options', item.optIdx)"
                    >
                      <slot
                        name="option"
                        :item="item"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item[itemText] }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </slot>
                    </v-list-item>
                  </template>
                </gm-scroller>
              </v-list>
            </v-card-text>
          </v-card>
        </v-flex>
        <!-- Controles -->
        <v-flex
          xs12
          md1
          class="d-flex justify-center"
        >
          <gm-control
            :messages="messages"
            :icons="icons"
            :height="height"
            :is-mobile="isMobile"
            @toggle="toggleHandler"
          />
        </v-flex>

        <v-flex
          xs12
          md5
        >
          <v-card class="transparencia">
            <v-card-title>
              <span style="font-size: 15px !important">
                {{ messages.listSelected }}
              </span>
            </v-card-title>

            <v-divider class="mx-2" />

            <v-card-text class="px-0 pb-0">
              <v-text-field
                v-model="searchSelected"
                :placeholder="messages.find"
                hide-details
                outlined
                class="px-2 mb-2"
                dense
                @keypress="updateScroller('selected')"
              />

              <v-list
                dense
                class="py-0 transparencia"
                :style="listStyle"
              >
                <gm-scroller
                  ref="scrollerSelected"
                  :size="40"
                  :remain="10"
                  class="scrollbar__seamless"
                  :style="listStyleListScroller"
                >
                  <template v-for="(item, i) in selected">
                    <v-list-item
                      :key="`option-item-${i}`"
                      :class="
                        precheck.selected.includes(item.sldIdx)
                          ? 'selected'
                          : ''
                      "
                      @click="handleClick('selected', item.sldIdx)"
                    >
                      <slot
                        name="selected"
                        :item="item"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item[itemText] }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </slot>
                    </v-list-item>
                  </template>
                </gm-scroller>
              </v-list>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </template>

    <template v-else>
      Vuetify not found
    </template>
  </div>
</template>

<script>
import VirtualList from 'vue-virtual-scroll-list'
import Control from './Control.vue'

import defaultValues from '@/components/DualList/default'
import mapping from '@/components/DualList/mapping'

export default {
  components: {
    'gm-control': Control,
    'gm-scroller': VirtualList,
  },

  model: {
    prop: 'preSelected',
    event: 'change',
  },

  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },

    preSelected: {
      type: Array,
      required: false,
      default: () => [],
    },

    itemText: {
      type: String,
      required: false,
      default: () => 'text',
    },

    itemValue: {
      type: String,
      required: false,
      default: () => 'value',
    },

    height: {
      type: Number || String,
      required: false,
      default: () => defaultValues.height,
    },

    heightScroller: {
      type: Number || String,
      required: false,
      default: () => defaultValues.heightScroller,
    },

    mobileBreakpoint: {
      type: Number || String,
      required: false,
      default: () => defaultValues.mobileBreakpoint,
    },

    messages: {
      type: Object,
      required: false,
      default: () => defaultValues.translations,
    },

    icons: {
      type: Object,
      required: false,
      default: () => defaultValues.iconsMdi,
    },

    anchoVentana: {
      type: Number,
      required: false,
      default: () => defaultValues.ancho,
    },
  },

  data() {
    return {
      width: window.innerWidth,
      isMac: false,
      altura: null,

      modifiers: {
        range: false,
        individual: false,
      },

      searchOptions: '',
      searchSelected: '',

      filtered: {
        options: [],
        selected: [],
      },

      lists: {
        options: [],
        selected: [],
      },

      precheck: {
        options: [],
        selected: [],
      },

      idxSelected: undefined,
    }
  },

  computed: {
    /**
     * Has Vuetify
     * Computed property to validate Vuetify presence
     *
     * @since  2020-04-09
     * @author Kenny Mochizuki
     *
     * @return {Boolean}
     *   Will return {true} if {$vuetify} is installed in Vue instance
     */
    hasVuetify() {
      return this.$vuetify !== undefined
    },

    /**
     * Is Mobile
     * Computed property to validate if is mobile using mobileBreakpoint
     *
     * @since  2020-04-09
     * @author Kenny Mochizuki
     *
     * @return {Boolean}
     *   Will return if {width} is less than or equals to {mobileBreakpoint}
     */
    isMobile() {
      return this.width <= this.mobileBreakpoint
    },

    /**
     * List style
     * Computed property with style of lists
     *
     * @since  2020-04-09
     * @author Kenny Mochizuki
     *
     * @return {String|CSS}
     *   Return CSS style string of styles
     */
    listStyle() {
      return `
        height: ${this.height}px;
        max-height: ${this.height}px;
      `
    },
    listStyleListScroller() {
      return `
        height: ${this.heightScroller}px !important;
        max-height: ${this.heightScroller}px !important;
      `
    },

    /**
     * Keyboard Mapping
     * Computed property with keyboard mapping
     * will return different based on {isMac} variable
     *
     * @since  2020-04-09
     * @author Kenny Mochizuki
     *
     * @return {Object}
     *   Return keyCodes mapping
     */
    keyboardMapping() {
      if (this.isMac) {
        return mapping.MacOS
      }

      return mapping.Other
    },

    options() {
      return this.lists.options
        .map((item, index) => ({
          ...item,
          optIdx: index,
        }))
        .filter((item, index) => this.filtered.options.includes(index))
    },

    selected() {
      return this.lists.selected
        .map((item, index) => ({
          ...item,
          sldIdx: index,
        }))
        .filter((item, index) => this.filtered.selected.includes(index))
    },
  },

  watch: {
    /**
     * Items watcher
     * Watch changes into items list and update options
     * Will reset all selected items and prechecked options
     *
     * @since  2020-04-09
     * @author Kenny Mochizuki
     *
     * @param newValue {Array}
     *   New list of items
     * @param oldValue {Array}
     *   Old list of items
     */
    items(newValue, oldValue) {
      this.lists = {
        options: [...newValue],
        selected: [],
      }

      this.filtered = {
        options: [],
        selected: [],
      }

      this.precheck = {
        options: [],
        selected: [],
      }

      this.search = {
        options: '',
        selected: '',
      }

      this.filterLists()
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    preSelected(newValue, oldValue) {
      this.updateListsThroughModel()
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    searchOptions(newValue, oldValue) {
      this.filterLists()
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    searchSelected(newValue, oldValue) {
      this.filterLists()
    },
  },

  created() {
    if (!this.hasVuetify) {
      throw new Error('Vuetify is required')
    }

    window.addEventListener('resize', this.updateSize)
    window.addEventListener('keydown', this.handleKeyPress)
    window.addEventListener('keyup', this.handleKeyPress)

    if (navigator.platform === 'MacIntel') {
      this.isMac = true
    }
  },

  beforeMount() {
    this.lists.options = [...this.items]
  },

  mounted() {
    this.updateListsThroughModel()
    this.filterLists()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.updateSize)
    window.removeEventListener('keydown', this.handleKeyPress)
    window.removeEventListener('keyup', this.handleKeyPress)
  },

  methods: {
    updateListsThroughModel() {
      const selected = []
      const options = []
      const preSelected = this.preSelected.map(item => item[this.itemValue])

      for (const i in this.items) {
        if (preSelected.includes(this.items[i][this.itemValue])) {
          selected.push(this.items[i])
        } else {
          options.push(this.items[i])
        }
      }

      this.lists = {
        options,
        selected,
      }

      this.filterLists()
    },

    filterLists() {
      this.filtered = {
        options: [],
        selected: [],
      }

      for (const i in this.lists.options) {
        if (this.validatePresence('options', this.lists.options[i])) {
          this.filtered.options.push(parseInt(i))
        }
      }

      for (const i in this.lists.selected) {
        if (this.validatePresence('selected', this.lists.selected[i])) {
          this.filtered.selected.push(parseInt(i))
        }
      }
    },

    /**
     * Handle Click
     * Handle item click (Valid for options and selected lists)
     *
     * @since  2020-04-09
     * @author Kenny Mochizuki
     *
     * @param source   {String}
     *   Name of source (options, selected)
     * @param position {Number}
     *   Position (index) of item to select (or unselect)
     */
    handleClick(source, position) {
      let result = [...this.precheck[source]]

      if (this.modifiers.individual) {
        const idx = result.indexOf(position)

        if (idx !== -1) {
          result.splice(idx, 1)
        } else {
          result.push(position)
        }
      } else if (this.modifiers.range) {
        if (this.idxSelected !== undefined) {
          let minimum = 0
          let maximum = 0

          if (this.idxSelected > position) {
            minimum = position
            maximum = this.idxSelected
          } else {
            minimum = this.idxSelected
            maximum = position
          }

          result = []

          for (let i = minimum; i <= maximum; i += 1) {
            result.push(i)
          }

          this.idxSelected = undefined
        } else {
          this.idxSelected = position
          result = [position]
        }
      } else {
        result = [position]
      }

      this.precheck[source] = [...result]
    },

    validatePresence(source, item) {
      let filter = this.searchOptions
      if (source === 'selected') {
        filter = this.searchSelected
      }

      if (filter.length === 0) {
        return true
      }

      const field = item[this.itemText]
      if (field) {
        return field.toLowerCase().includes(filter.toLowerCase())
      }

      return false
    },

    /**
     * Handle keyPress
     * Handle keyPress listener
     *
     * @notes
     *   See {mapping.js} file to get the mapping
     *
     * @since  2020-04-09
     * @author Kenny Mochizuki
     *
     * @param event {Object}
     *   keyPress event object
     */
    handleKeyPress(event) {
      if (this.keyboardMapping.range === event.keyCode) {
        this.modifiers.range = event.type === 'keydown'
      }

      if (this.keyboardMapping.indLeft === event.keyCode) {
        this.modifiers.individual = event.type === 'keydown'
      }

      if (this.keyboardMapping.indRight === event.keyCode) {
        this.modifiers.individual = event.type === 'keydown'
      }
    },

    /**
     * Update Size
     * Handle resize listener, will set a new width
     *
     * @since  2020-04-09
     * @author Kenny Mochizuki
     *
     * @param event {Object}
     *   Resize event object
     */
    updateSize(event) {
      this.width = event.target.innerWidth
    },

    updateScroller(source) {
      if (source === 'options') {
        this.$refs.scrollerOptions.forceRender()
      } else {
        this.$refs.scrollerSelected.forceRender()
      }
    },

    sortLists() {
      this.lists.options.sort((a, b) => {
        if (a[this.itemText] > b[this.itemText]) {
          return 1
        }
        if (b[this.itemText] > a[this.itemText]) {
          return -1
        }

        return 0
      })

      this.lists.selected.sort((a, b) => {
        if (a[this.itemText] > b[this.itemText]) {
          return 1
        }
        if (b[this.itemText] > a[this.itemText]) {
          return -1
        }

        return 0
      })
    },

    toggleHandler({ action }) {
      if (action === 'forwardAll') {
        this.filtered = {
          options: [],
          selected: [],
        }

        this.precheck = {
          options: [],
          selected: [],
        }

        this.lists = {
          options: [],
          selected: [...this.items],
        }
      } else if (action === 'forward') {
        const selected = [...this.lists.selected]
        const options = []

        for (const i in this.lists.options) {
          if (this.precheck.options.includes(parseInt(i))) {
            selected.push(this.lists.options[i])
          } else {
            options.push(this.lists.options[i])
          }
        }

        this.lists = {
          options,
          selected,
        }

        this.precheck.options = []
      } else if (action === 'backward') {
        const selected = []
        const options = [...this.lists.options]

        for (const i in this.lists.selected) {
          if (this.precheck.selected.includes(parseInt(i))) {
            options.push(this.lists.selected[i])
          } else {
            selected.push(this.lists.selected[i])
          }
        }

        this.lists = {
          options,
          selected,
        }

        this.precheck.selected = []
      } else if (action === 'backwardAll') {
        this.filtered = {
          options: [],
          selected: [],
        }

        this.precheck = {
          options: [],
          selected: [],
        }

        this.lists = {
          options: [...this.items],
          selected: [],
        }
      }

      this.sortLists()

      this.filterLists()
      this.updateScroller('options')
      this.updateScroller('selected')

      this.$emit('change', this.lists.selected)
    },
  },
}
</script>

<style lang="scss">
.selected {
  background: var(--v-primary-base);
  & > * {
    color: #fff !important;
  }
}

.scroller {
  height: 200px;
}

.scrollbar__seamless {
  //Chrome and updated browsers Only
  &::-webkit-scrollbar {
    width: 5px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(126, 124, 124, 0.76) !important;
    border-radius: 10px !important;
  }

  &::-webkit-scrollbar-track {
    background: rgba(243, 239, 239, 0.726) !important;
  }
}
</style>
